import palette from 'theme/palette';
import moment from 'moment';
import {
  DAILY,
  WEEKLY,
  MONTHLY,
  DAILY_HOURS,
  WEEKLY_DAYS,
} from '../../../../constants';

export const getBenchmarkTime = (items, viewMode) => {
  let latestDate = null;
  if (viewMode === DAILY) {
    items && items.map(item => {
      if (latestDate === null) {
        latestDate = moment(item.dateandtime);
      } else {
        if (latestDate.isBefore(item.dateandtime, 'day')) {
          latestDate = moment(item.dateandtime);
        }
      }
    });

    if (latestDate === null) return moment();
    else return latestDate;
  } else {
    return moment();
  }
}

/*
*   make 'Friday 03/06/2020 03:45 PM' from ['Friday', '03/06/2020', '03:45 PM']
* */
export const makeCorrectDate = (arr) => {
  let ret = '';
  arr && arr.map((item, index) => {
    if (index !== 0) {
      ret += ' ' + item;
    } else {
      ret += item;
    }
  });

  return ret;
};
/*
*   dateanditem 'Friday - 03/06/2020 03:45 - PM' to 'Friday 03/06/2020 03:45 PM'
* */
export const makeCorrectDateItem = (items) => {
  items && items.map(item => {
    const date = item.dateandtime && item.dateandtime.split(' - ');
    item.dateandtime = makeCorrectDate(date);
  });

  return items;
};
/*
*   filter analytics data which date is later than date: date_param - n
* */
export const filterAnalyticsWithinDate = (items, benchmark, n) => {
  const st = moment(benchmark.format('MM/DD/YYYY'));

  let data = [];
  items && items.map(item => {
    const da = moment(moment(item.dateandtime).format('MM/DD/YYYY'));
    const diff = Math.floor(st.diff(da, 'days', true));

    if (diff < n && diff >= 0) {
      data.push(item);
    }
  });

  return data;
};
/*
*   filter daily data by current time, returns items before current time
* */
export const getCorrectDailyItem = (items) => {
  const st = moment().format('H');
  let data = [];
  items && items.map(item => {
    const da = moment(item.dateandtime).format('H');

    if (parseInt(da) <= parseInt(st)) {
      data.push(item);
    }
  });

  return data;
}
/*
*   make data structure for chart with analytics data structure
* */
export const processData = (analytics, viewMode) => {
  const items = analytics &&
    analytics.analytics &&
    analytics.analytics.Items;
  const data = items && makeCorrectDateItem(items);
  // const now = moment();
  const now = getBenchmarkTime(data, viewMode);

  let filteredData;
  let ret;
  if (viewMode === DAILY) {
    filteredData = filterAnalyticsWithinDate(data, now, 1);
    const correctData = getCorrectDailyItem(filteredData);
    ret = formatDailyAnalytics(correctData);
  } else if (viewMode === WEEKLY) {
    filteredData = filterAnalyticsWithinDate(data, now, 7);
    ret = formatWeeklyAnalytics(filteredData);
  } else {
    filteredData = filterAnalyticsWithinDate(data, now, 31);
    ret = formatMonthlyAnalytics(filteredData);
  }

  return ret;
};
/*
*   aggregates the daily filtered data
* */
export const formatDailyAnalytics = (data) => {
  let finalData = [];
  data && data.map(item => {
    const hour = moment(item.dateandtime).format('h');
    const ap = moment(item.dateandtime).format('a');
    const label = moment(item.dateandtime).format('ha');

    if ((ap === 'am' && hour >= 6) || (ap === 'pm' && (hour < 9 || hour === '12'))) { // after 6am and before 9pm
      const index = finalData.findIndex(ele => ele.label === label);

      if (index === -1) { // not exist
        finalData.push({label, count: 1, totalMaximum: item.maximum, maxMaximum: item.maximum});
      } else {
        const prevData = JSON.parse(JSON.stringify(finalData[index]));
        let maxMaximum = 0;
        if (finalData[index]['maxMaximum'] < item.maximum) {
          maxMaximum = item.maximum;
        } else {
          maxMaximum = finalData[index]['maxMaximum'];
        }

        finalData[index] = {label, count: prevData.count + 1, totalMaximum: prevData.totalMaximum + item.maximum, maxMaximum};
      }
    }
  });

  return finalData;
};
/*
*   aggregates the weekly filtered data
* */
export const formatWeeklyAnalytics = (data) => {
  let finalData = [];
  data && data.map(item => {
    const label = moment(item.dateandtime).format('ddd');
    const index = finalData.findIndex(ele => ele.label === label);
    const assistLabel = moment(item.dateandtime).format('M/D');

    if (index === -1) { // not exist
      finalData.push({label, count: 1, totalMaximum: item.maximum, assistLabel, maxMaximum: item.maximum});
    } else {
      const prevData = JSON.parse(JSON.stringify(finalData[index]));
      let maxMaximum = 0;
      if (finalData[index]['maxMaximum'] < item.maximum) {
        maxMaximum = item.maximum;
      } else {
        maxMaximum = finalData[index]['maxMaximum'];
      }

      finalData[index] = {label, count: prevData.count + 1, totalMaximum: prevData.totalMaximum + item.maximum, maxMaximum, assistLabel};
    }
  });

  return finalData;
};
/*
*   aggregates monthly filtered data
* */
export const formatMonthlyAnalytics = (data) => {
  let finalData = [];
  data && data.map(item => {
    const label = moment(item.dateandtime).format('M/D');
    const index = finalData.findIndex(ele => ele.label === label);

    if (index === -1) { // not exist
      finalData.push({label, count: 1, totalMaximum: item.maximum, maxMaximum: item.maximum});
    } else {
      const prevData = JSON.parse(JSON.stringify(finalData[index]));
      let maxMaximum = 0;
      if (finalData[index]['maxMaximum'] < item.maximum) {
        maxMaximum = item.maximum;
      } else {
        maxMaximum = finalData[index]['maxMaximum'];
      }

      finalData[index] = {label, count: prevData.count + 1, totalMaximum: prevData.totalMaximum + item.maximum, maxMaximum};
    }
  });

  return finalData;
};
/*
*   make the X axis labels for monthly mode
* */
export const getMonthLabels = () => {
  const benchmarkTime = getBenchmarkTime();
  let startDate = benchmarkTime.subtract(1, 'month');
  let monthLabels = [];
  let i = 0;
  while (i <= 31) {
    monthLabels.push(startDate.format('M/D'));
    startDate = startDate.add(1, 'day');
    i++;
  }

  return monthLabels;
}
/*
*   make data for chart's data
* */
export const getChartData = (analytics, viewMode) => {
  const data = processData(analytics, viewMode);

  let chartData = {
    labels: [],
    datasets: [
      {
        lable: '',
        backgroundColor: palette.primary.main,
        data: [],
      },
    ],
  };

  if (viewMode === DAILY) { // daily mode
    DAILY_HOURS.map(hour => {
      chartData.labels.push(hour);
      const hourItem = data && data.find(item => item.label === hour);

      if (hourItem) {
        chartData.datasets[0].data.push(hourItem.maxMaximum || 0);
      } else {
        chartData.datasets[0].data.push(0);
      }
    })
  } else if (viewMode === WEEKLY) { // weekly mode
    const benchmarkDay = getBenchmarkTime().format('ddd');
    const clonedWeekDays = JSON.parse(JSON.stringify(WEEKLY_DAYS));
    const len = clonedWeekDays.length
    const index = clonedWeekDays.findIndex(item => item === benchmarkDay);
    const sliced = clonedWeekDays.splice(index + 1, len - index - 1);
    const orderedWeek = sliced.concat(clonedWeekDays);

    orderedWeek.map(day => {
      const dayItem = data && data.find(item => item.label === day);

      if (dayItem) {
        chartData.labels.push(day + ', ' + dayItem.assistLabel);
        chartData.datasets[0].data.push(dayItem.maxMaximum || 0);
      } else {
        chartData.labels.push(day);
        chartData.datasets[0].data.push(0);
      }
    })
  } else if (viewMode === MONTHLY) { // monthly mode
    getMonthLabels();
    const monthLabels = getMonthLabels();

    monthLabels &&
    monthLabels.map(day => {
      chartData.labels.push(day);
      const dayItem = data && data.find(item => item.label === day);

      if (dayItem) {
        chartData.datasets[0].data.push(dayItem.maxMaximum || 0);
      } else {
        chartData.datasets[0].data.push(0);
      }
    })
  }

  return chartData;
};
