import React from 'react';
import {withStyles} from '@material-ui/core';
import {compose} from 'redux';
import connect from 'react-redux/es/connect/connect';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Card, CardContent, Grid, Typography, Avatar } from '@material-ui/core';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import classNames from "classnames";

const styles = theme => (
  {
    root: {
      height: '100%'
    },
    content: {
      alignItems: 'center',
      display: 'flex'
    },
    title: {
      fontWeight: 700
    },
    avatar: {
      backgroundColor: theme.palette.error.main,
      height: 56,
      width: 56
    },
    icon: {
      height: 32,
      width: 32
    },
    difference: {
      marginTop: theme.spacing(2),
      display: 'flex',
      alignItems: 'center'
    },
    differenceIcon: {
      color: theme.palette.error.dark
    },
    differenceValue: {
      color: theme.palette.error.dark,
      marginRight: theme.spacing(1)
    }
  });

class Occupied extends React.Component {
  
  render() {
    const { classes, className, ...rest } = this.props;
    return (
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <CardContent>
          <Grid
            container
            justify="space-between"
          >
            <Grid item>
            
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
                variant="body2"
              >
                OCCUPIED
              </Typography>
          
            </Grid>
            <Grid item>
              <Avatar className={classes.avatar}>
                <EventBusyIcon className={classNames(classes.icon)} />
              </Avatar>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

Occupied.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default compose(
  withStyles(styles, {
    name: 'Occupied',
  }),
  connect(
    state => (
      {

      }),
    {}
  ),
)(Occupied);
