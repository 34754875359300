import handlers from './handler';
import {API_HOST} from '../configs/hosts';

let socket;

export async function connectSocket(io) {
  socket = io(API_HOST);
  handlers.forEach(handler => {
    socket.on(handler.channel, handler.action);
  });
  return new Promise((resolve, reject) => {
    socket.on('connection', (response) => {
      if (response.connection) {
        resolve(response.connection)
      }
      else {
        reject(response)
      }
    });
  })
}

export function socketEmit(channel, message) {
  socket.emit(channel, message)
}
