import palette from 'theme/palette';
import moment from 'moment';
import { create, all } from 'mathjs';
import {
  DAILY,
  WEEKLY,
  MONTHLY,
  DAILY_HOURS,
  WEEKLY_DAYS,
} from '../../../../constants';

export const getBenchmarkTime = (items, viewMode) => {
  let latestDate = null;
  if (viewMode === DAILY) {
    items && items.map(item => {
      if (latestDate === null) {
        latestDate = moment(item.dateandtime);
      } else {
        if (latestDate.isBefore(item.dateandtime, 'day')) {
          latestDate = moment(item.dateandtime);
        }
      }
    });

    if (latestDate === null) return moment();
    else return latestDate;
  } else {
    return moment();
  }
}

/*
*   make 'Friday 03/06/2020 03:45 PM' from ['Friday', '03/06/2020', '03:45 PM']
* */
export const makeCorrectDate = (arr) => {
  let ret = '';
  arr && arr.map((item, index) => {
    if (index !== 0) {
      ret += ' ' + item;
    } else {
      ret += item;
    }
  });

  return ret;
};
/*
*   dateanditem 'Friday - 03/06/2020 03:45 - PM' to 'Friday 03/06/2020 03:45 PM'
* */
export const makeCorrectDateItem = (items) => {
  items && items.map(item => {
    const date = item.dateandtime && item.dateandtime.split(' - ');
    item.dateandtime = makeCorrectDate(date);
  });

  return items;
};
/*
*   filter analytics data which date is later than date: date_param - n
* */
export const filterAnalyticsWithinDate = (items, benchmark, n) => {
  const st = moment(benchmark.format('MM/DD/YYYY'));

  let data = [];
  items && items.map(item => {
    const da = moment(moment(item.dateandtime).format('MM/DD/YYYY'));
    const diff = Math.floor(st.diff(da, 'days', true));

    if (diff < n && diff >= 0) {
      data.push(item);
    }
  });

  return data;
};
/*
*   filter daily data by current time, returns items before current time
* */
export const getCorrectDailyItem = (items) => {
  const st = moment().format('H');
  let data = [];
  items && items.map(item => {
    const da = moment(item.dateandtime).format('H');

    if (parseInt(da) <= parseInt(st)) {
      data.push(item);
    }
  });

  return data;
}
/*
*   make data structure for chart with analytics data structure
* */
export const processData = (analytics, viewMode, argument) => {
  const items = analytics &&
    analytics.analytics &&
    analytics.analytics.Items;
  const data = items && makeCorrectDateItem(items);
  // const now = moment();
  const now = getBenchmarkTime(data, viewMode);

  let filteredData;
  let ret;
  if (viewMode === DAILY) {
    filteredData = filterAnalyticsWithinDate(data, now, 1);
    const correctData = getCorrectDailyItem(filteredData);
    ret = formatDailyAnalytics(correctData);
  } else if (viewMode === WEEKLY) {
    filteredData = filterAnalyticsWithinDate(data, now, 7);
    ret = formatWeeklyAnalytics(filteredData);
  } else {
    filteredData = filterAnalyticsWithinDate(data, now, 31);
    ret = formatMonthlyAnalytics(filteredData);
  }

  return ret;
};
/*
*   aggregates the daily filtered data
* */
export const formatDailyAnalytics = (data) => {
  let finalData = [];

  const math = create(all, { });
  var allAvgs = [];
  var allMaxs = [];

  data && data.map(item => {
    const hour = moment(item.dateandtime).format('h');
    const ap = moment(item.dateandtime).format('a');
    const label = moment(item.dateandtime).format('ha');

    if ((ap === 'am' && hour >= 6) || (ap === 'pm' && (hour < 9 || hour === '12'))) { // after 6am and before 9pm
      const index = finalData.findIndex(ele => ele.label === label);
      allAvgs.push(item.average);
      allMaxs.push(item.maximum);
      if(index === -1) {
        finalData.push({
          label,
          count: 1,
          totalHumidity: item.humidity,
          maxHumidity: item.humidity,
          totalAverage: parseInt(item.average),
          totalMaximum: parseInt(item.maximum),
          totalLightDetected: item.isLightDetected ? 1 : 0,
          totalTemperature: item.temperatureInF
        });
      } else {
        const prevData = JSON.parse(JSON.stringify(finalData[index]));
        // let maxHumidity = 0;
        // if (finalData[index]['maxHumidity'] < item.humidity) {
        //   maxHumidity = item.humidity;
        // } else {
        //   maxHumidity = finalData[index]['maxHumidity'];
        // }

        let totalHumidity = prevData.totalHumidity + item.humidity;
        let count = prevData.count + 1;
        let totalAverage = prevData.totalAverage + parseInt(item.average);
        let totalMaximum = prevData.totalMaximum + parseInt(item.maximum);
        let totalLightDetected = item.isLightDetected ? prevData.totalLightDetected + 1 : prevData.totalLightDetected;
        let totalTemperature = prevData.totalTemperature + item.temperatureInF;
        finalData[index] = {
          label,
          count: count,
          totalHumidity: totalHumidity,
          totalAverage: totalAverage,
          totalMaximum: totalMaximum,
          totalLightDetected: totalLightDetected,
          totalTemperature: totalTemperature,
        };
      }
    }
  });

  let maxs = ['0'];
  if(allMaxs.length > 0 ) {
    maxs = math.mode(allMaxs);
  }
  let avgs = ['0'];
  if(allMaxs.length > 0 ) {
    avgs = math.mode(allAvgs);
  }

  finalData.map((data, index) => {
    let humidity = Math.round(data.totalHumidity / data.count );
    let average = Math.round(data.totalAverage / data.count );
    let maximum = Math.round(data.totalMaximum / data.count );
    let isLightDetected = 2 * data.totalLightDetected > data.count ? true: false;
    let temperature = Math.round( data.totalTemperature / data.count );

    finalData[index] = {
      label: data.label,
      humidity: humidity,
      average: average,
      maximum: maximum,
      isLightDetected: isLightDetected,
      temperature: temperature,
      assistLabel: data.assistLabel
    };
  });

  return finalData;
};
/*
*   aggregates the weekly filtered data
* */
export const formatWeeklyAnalytics = (data) => {
  let finalData = [];

  const math = create(all, { });
  var allAvgs = [];
  var allMaxs = [];

  data && data.map(item => {
    const label = moment(item.dateandtime).format('ddd');
    const index = finalData.findIndex(ele => ele.label === label);
    const assistLabel = moment(item.dateandtime).format('M/D');
    allAvgs.push(item.average);
    allMaxs.push(item.maximum);

    if (index === -1) { // not exist
      finalData.push({
        label,
        count: 1,
        totalHumidity: item.humidity,
        assistLabel,
        maxHumidity: item.humidity,
        totalAverage: parseInt(item.average),
        totalMaximum:  parseInt(item.maximum),
        totalLightDetected: item.isLightDetected ? 1 : 0,
        totalTemperature: item.temperatureInF
      });
    } else {
      const prevData = JSON.parse(JSON.stringify(finalData[index]));
      // let maxHumidity = 0;
      // if (finalData[index]['maxHumidity'] < item.humidity) {
      //   maxHumidity = item.humidity;
      // } else {
      //   maxHumidity = finalData[index]['maxHumidity'];
      // }

      let totalHumidity = prevData.totalHumidity + item.humidity;
      let count = prevData.count + 1;
      let totalAverage = prevData.totalAverage +  parseInt(item.average);
      let totalMaximum = prevData.totalMaximum +  parseInt(item.maximum);
      let totalLightDetected = item.isLightDetected ? prevData.totalLightDetected + 1 : prevData.totalLightDetected;
      let totalTemperature = prevData.totalTemperature + item.temperatureInF;
      finalData[index] = {
        label,
        count: count,
        totalHumidity: totalHumidity,
        totalAverage: totalAverage,
        totalMaximum: totalMaximum,
        totalLightDetected: totalLightDetected,
        totalTemperature: totalTemperature,
        assistLabel
      };
    }
  });

  let maxs = ['0'];
  if(allMaxs.length > 0 ) {
    maxs = math.mode(allMaxs);
  }
  let avgs = ['0'];
  if(allMaxs.length > 0 ) {
    avgs = math.mode(allAvgs);
  }

  finalData.map((data, index) => {
    let humidity = Math.round(data.totalHumidity / data.count );
    // let average = parseInt(avgs[0]);
    // let maximum = parseInt(maxs[0]);
    let average = Math.round(data.totalAverage / data.count );
    let maximum = Math.round(data.totalMaximum / data.count );
    let isLightDetected = 2 * data.totalLightDetected > data.count ? true: false;
    let temperature = Math.round( data.totalTemperature / data.count );

    finalData[index] = {
      label: data.label,
      humidity: humidity,
      average: average,
      maximum: maximum,
      isLightDetected: isLightDetected,
      temperature: temperature,
      assistLabel: data.assistLabel
    };
  });

  console.log(finalData);
  return finalData;
};
/*
*   aggregates monthly filtered data
* */
export const formatMonthlyAnalytics = (data) => {
  let finalData = [];

  const math = create(all, { });
  var allAvgs = [];
  var allMaxs = [];

  data && data.map(item => {
    const label = moment(item.dateandtime).format('M/D');
    const index = finalData.findIndex(ele => ele.label === label);

    allAvgs.push(item.average);
    allMaxs.push(item.maximum);
    if (index === -1) { // not exist
      finalData.push({
        label,
        count: 1,
        totalHumidity: item.humidity,
        maxHumidity: item.humidity,
        totalAverage: parseInt(item.average),
        totalMaximum:  parseInt(item.maximum),
        totalLightDetected: item.isLightDetected ? 1 : 0,
        totalTemperature: item.temperatureInF
      });
    } else {
      const prevData = JSON.parse(JSON.stringify(finalData[index]));
      // let maxHumidity = 0;
      // if (finalData[index]['maxHumidity'] < item.humidity) {
      //   maxHumidity = item.humidity;
      // } else {
      //   maxHumidity = finalData[index]['maxHumidity'];
      // }

      let totalHumidity = prevData.totalHumidity + item.humidity;
      let count = prevData.count + 1;
      let totalAverage = prevData.totalAverage +  parseInt(item.average);
      let totalMaximum = prevData.totalMaximum +  parseInt(item.maximum);
      let totalLightDetected = item.isLightDetected ? prevData.totalLightDetected + 1 : prevData.totalLightDetected;
      let totalTempperature = prevData.totalTemperature + item.temperatureInF;
      finalData[index] = {
        label,
        count: count,
        totalHumidity: totalHumidity,
        totalAverage: totalAverage,
        totalMaximum: totalMaximum,
        totalLightDetected: totalLightDetected,
        totalTemperature: totalTempperature,
      };
    }
  });

  let maxs = ['0'];
  if(allMaxs.length > 0 ) {
    maxs = math.mode(allMaxs);
    console.log(maxs);
  }
  let avgs = ['0'];
  if(allMaxs.length > 0 ) {
    avgs = math.mode(allAvgs);
    console.log(avgs);

  }

  finalData.map((data, index) => {
    let humidity = Math.round(data.totalHumidity / data.count );
    // let average = parseInt(avgs[0]);
    // let maximum = parseInt(maxs[0]);
    let average = Math.round(data.totalAverage / data.count );
    let maximum = Math.round(data.totalMaximum / data.count );
    let isLightDetected = 2 * data.totalLightDetected > data.count ? true: false;
    let temperature = Math.round( data.totalTemperature / data.count );

    finalData[index] = {
      label: data.label,
      humidity: humidity,
      average: average,
      maximum: maximum,
      isLightDetected: isLightDetected,
      temperature: temperature,
    };
  });

  console.log(finalData);
  return finalData;
};
/*
*   make the X axis labels for monthly mode
* */
export const getMonthLabels = () => {
  const benchmarkTime = getBenchmarkTime();
  let startDate = benchmarkTime.subtract(1, 'month');
  let monthLabels = [];
  let i = 0;
  while (i <= 31) {
    monthLabels.push(startDate.format('M/D'));
    startDate = startDate.add(1, 'day');
    i++;
  }

  return monthLabels;
}
/*
*   make data for chart's data
* */
export const getChartData = (analytics, viewMode, argument) => {
  const data = processData(analytics, viewMode, argument);

  let chartData = {
    labels: [],
    datasets: [
      {
        lable: '',
        backgroundColor: palette.primary.main,
        data: [],
      },
    ],
  };

  if (viewMode === DAILY) { // daily mode
    DAILY_HOURS.map(hour => {
      chartData.labels.push(hour);
      const hourItem = data && data.find(item => item.label === hour);

      if (hourItem) {
        chartData.datasets[0].data.push( getChartDataByArgument(hourItem, argument) || 0);
      } else {
        chartData.datasets[0].data.push(0);
      }
    })
  } else if (viewMode === WEEKLY) { // weekly mode
    const benchmarkDay = getBenchmarkTime().format('ddd');
    const clonedWeekDays = JSON.parse(JSON.stringify(WEEKLY_DAYS));
    const len = clonedWeekDays.length
    const index = clonedWeekDays.findIndex(item => item === benchmarkDay);
    const sliced = clonedWeekDays.splice(index + 1, len - index - 1);
    const orderedWeek = sliced.concat(clonedWeekDays);

    orderedWeek.map(day => {
      const dayItem = data && data.find(item => item.label === day);

      if (dayItem) {
        chartData.labels.push(day + ', ' + dayItem.assistLabel);
        chartData.datasets[0].data.push(getChartDataByArgument(dayItem, argument) || 0);
      } else {
        chartData.labels.push(day);
        chartData.datasets[0].data.push(0);
      }
    })
  } else if (viewMode === MONTHLY) { // monthly mode
    getMonthLabels();
    const monthLabels = getMonthLabels();

    monthLabels &&
    monthLabels.map(day => {
      chartData.labels.push(day);
      const dayItem = data && data.find(item => item.label === day);

      if (dayItem) {
        chartData.datasets[0].data.push(getChartDataByArgument(dayItem, argument) || 0);
      } else {
        chartData.datasets[0].data.push(0);
      }
    })
  }

  return chartData;
};

const getChartDataByArgument = (data, argument) => {
  console.log( data );
  switch( argument ) {
    case 'Average':
      return data.average;
    case 'Maximum':
      return  data.maximum;
    case 'Humidity':
      return  data.humidity;
    case 'isLightDetected':
      return  data.isLightDetected;
    case 'Temperature':
      return data.temperature;
    default:
      return data.humidity;
  }
}
