import palette from 'theme/palette';
import {
  DAILY,
  MONTHLY,
  WEEKLY,
} from '../../../../constants';
import {monthStr} from '../../../../common/dateFormat';


export const getAP = dateTimeStr => {
  const len = dateTimeStr && dateTimeStr.length;

  return dateTimeStr && dateTimeStr.substring(len - 2, len);
};

export const getHour = dateTimeStr => {
  const len = dateTimeStr && dateTimeStr.length;

  return dateTimeStr && dateTimeStr.substring(len - 8, len - 6);
};

export const getDate = dateTimeStr => {
  const len = dateTimeStr && dateTimeStr.length

  return dateTimeStr && dateTimeStr.substring(len - 21, len - 11);
};

export const getMonth = dateTimeStr => {
  const dateStr = getDate(dateTimeStr);
  const dateArr = dateStr && dateStr.split('/');
  return dateStr && dateArr[0];
};

export const getYear = dateTimeStr => {
  const dateStr = getDate(dateTimeStr);
  const dateArr = dateStr && dateStr.split('/');
  return dateStr && dateArr[2];
};

export const getDay = dateTimeStr => {
  const dateStr = getDate(dateTimeStr);
  const dateArr = dateStr && dateStr.split('/');
  return dateStr && dateArr[1];
}

export const filterData = (data, viewMode) => {
  const items = data && data.analytics && JSON.parse(JSON.stringify(data.analytics.Items));

  if (viewMode === WEEKLY) { // weekly mode
    let aggregatedItems = [];

    items && items.map(item => {
      if (item.weekNumberOfYear) {
        const year = getYear(item.dateandtime);
        const index = aggregatedItems.findIndex(ele => ele.week === item.weekNumberOfYear);


        if (index !== -1) { // already exist
          const prevData = JSON.parse(JSON.stringify(aggregatedItems[index]));
          aggregatedItems[index]['Maximum'] = prevData.Maximum + item.maximum;
          aggregatedItems[index]['count'] = prevData.count + 1;
        } else {
          const newItem = {week: item.weekNumberOfYear, Maximum: item.maximum, count: 1 , year};
          aggregatedItems.push(newItem);
        }
      }
    });

    return aggregatedItems;
  } else if (viewMode === MONTHLY) { // monthly mode
    let aggregatedItems = [];

    items && items.map(item => {
      if (item.dateandtime) { // because there is undefined dateandtime
        const month = getMonth(item.dateandtime);
        const year = getYear(item.dateandtime);
        const yearMonthStr = month + '/' + year;
        const index = aggregatedItems.findIndex(ele => ele.yearMonth === yearMonthStr);

        if (index !== -1) { // already exist
          const prevData = JSON.parse(JSON.stringify(aggregatedItems[index]));
          aggregatedItems[index]['Maximum'] = prevData.Maximum + item.maximum;
          aggregatedItems[index]['count'] = prevData.count + 1;
        } else {
          const newItem = {yearMonth: yearMonthStr, Maximum: item.maximum, count: 1, month, year};
          aggregatedItems.push(newItem);
        }
      }
    });

    return aggregatedItems;
  } else { // daily mode
    /*
    * to show only 6am to 8pm
    * */
    const filteredItems = items && items.filter(item => {
      const dateAP = getAP(item.dateandtime);
      const dateHour = getHour(item.dateandtime);

      if (dateAP === 'AM') {
        return parseInt(dateHour) >= 6;
      } else if (dateAP === 'PM') {
        return parseInt(dateHour) < 8;
      } else {
        return false;
      }
    });

    let aggregatedItems = [];

    /*
    * aggregates on date
    * */
    filteredItems && filteredItems.map(item => {
      const dateDate = getDate(item.dateandtime);
      const day = getDay(item.dateandtime);
      const month = getMonth(item.dateandtime);
      const year = getYear(item.dateandtime);

      const index = aggregatedItems.findIndex(ele => ele.date === dateDate);

      if (index !== -1) { // already exist
        const prevData = JSON.parse(JSON.stringify(aggregatedItems[index]));
        aggregatedItems[index]['Maximum'] = prevData.Maximum + item.maximum;
        aggregatedItems[index]['count'] = prevData.count + 1;
      } else {
        const newItem = {date: dateDate, Maximum: item.maximum, count: 1, year, month, day};
        aggregatedItems.push(newItem);
      }
    });

    return aggregatedItems;
  }
};

export const sortData = (data, viewMode) => {
  if (viewMode === WEEKLY) {
    return data && data.sort((a, b) => {
      const week = parseInt(a.week);
      const fweek = parseInt(b.week);
      return week - fweek;
    });
  } else if (viewMode === MONTHLY) {
    return data && data.sort((a, b) => {
      const year = parseInt(a.year);
      const fyear = parseInt(b.year);
      return year - fyear;
    });
  } else {
    data && data.sort((a, b) => {
      const year = parseInt(a.year);
      const month = parseInt(a.month);
      const day = parseInt(a.day);
      const fyear = parseInt(b.year);
      const fmonth = parseInt(b.month);
      const fday = parseInt(b.day);

      if (year > fyear) {
        return 1;
      } else if (year === fyear) {
        if (month > fmonth) {
          return 1;
        } else if (month === fmonth) {
          if (day > fday) {
            return 1;
          } else {
            return -1;
          }
        } else {
          return -1;
        }
      } else {
        return -1;
      }
    });

    return data;
  }
}

export const formatData = (data, viewMode) => {
  let chartData = {
    labels: [],
    datasets: [
      {
        lable: '',
        backgroundColor: palette.primary.main,
        data: [],
      },
    ],
  };

  if (viewMode === WEEKLY) { // weekly mode
    data && data.map(item => {
      chartData.labels.push(item.week);
      item.count && chartData.datasets[0].data.push((item.maximum / item.count).toFixed(1));
    });

    return chartData;
  } else if (viewMode === MONTHLY) { // monthly mode
    data && data.map(item => {
      chartData.labels.push(monthStr[item.month]);
      item.count && chartData.datasets[0].data.push((item.maximum / item.count).toFixed(1));
    });

    return chartData;
  } else { // daily mode
    data && data.map(item => {
      chartData.labels.push(item.day);
      item.count && chartData.datasets[0].data.push((item.maximum / item.count).toFixed(1));
    });

    return chartData;
  }
};

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  animation: false,
  legend: { display: false },
  cornerRadius: 20,
  tooltips: {
    enabled: true,
    mode: 'index',
    intersect: false,
    borderWidth: 1,
    borderColor: palette.divider,
    backgroundColor: palette.white,
    titleFontColor: palette.text.primary,
    bodyFontColor: palette.text.secondary,
    footerFontColor: palette.text.secondary
  },
  layout: { padding: 0 },
  scales: {
    xAxes: [
      {
        barThickness: 12,
        maxBarThickness: 10,
        barPercentage: 0.5,
        categoryPercentage: 0.5,
        ticks: {
          fontColor: palette.text.secondary
        },
        gridLines: {
          display: false,
          drawBorder: false
        }
      }
    ],
    yAxes: [
      {
        ticks: {
          max: 100,
          stepSize: 10,
          fontColor: palette.text.secondary,
          beginAtZero: true,
          min: 0
        },
        gridLines: {
          borderDash: [2],
          borderDashOffset: [2],
          color: palette.divider,
          drawBorder: false,
          zeroLineBorderDash: [2],
          zeroLineBorderDashOffset: [2],
          zeroLineColor: palette.divider
        }
      }
    ]
  }
};
