import React from 'react';
import {compose} from 'redux';
import PropTypes from 'prop-types';
import connect from 'react-redux/es/connect/connect';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  withStyles
} from '@material-ui/core';
import clsx from 'clsx';
import { Bar } from 'react-chartjs-2';
import {
  options,
} from './chart';
import {
  getChartData,
} from './ChartLogic';
import {
  getAnalytics,
  setLiveViewMode,
  setAnalyticsArgument
} from '../../../../actions/analytic.action';
import {DAILY, MONTHLY, WEEKLY} from "../../../../constants";

const styles = theme => (
  {
    root: {},
    chartContainer: {
      height: 400,
      position: 'relative'
    },
    actions: {
      justifyContent: 'flex-end'
    },
    formControl: {
      margin: theme.spacing(1), minWidth: 120
    },
  });

class LatestSales extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const {
      getAnalytics,
    } = this.props;

    if (getAnalytics)
      getAnalytics();
  }

  render() {

    const {
      classes,
      className,
      analytics,
      viewMode,
      setLiveViewMode,
      argument,
      setAnalyticsArgument,
      ...rest
    } = this.props;

    const data = getChartData(analytics, viewMode, argument);

    return (
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <CardHeader
          /*action={
            <Button
              size="small"
              variant="text"
            >
              Last 7 days <ArrowDropDownIcon />
            </Button>
          }*/
          action={
            <>
              <FormControl className={classes.formControl}>
                <Select
                  value={argument}
                  color={'secondary'}
                  displayEmpty
                  onChange={(event) => {
                    setAnalyticsArgument(event.target.value);
                  }}
                >
                  <MenuItem value="Average">Average</MenuItem>
                  <MenuItem value="Maximum">Maximum</MenuItem>
                  <MenuItem value="Humidity">Humidity</MenuItem>
                  <MenuItem value="isLightDetected">isLightDetected</MenuItem>
                  <MenuItem value="Temperature">Temperature</MenuItem>
                </Select>
              </FormControl>
              <Button
                className={'view-mode'}
                color={viewMode === DAILY ? 'secondary' : 'primary'}
                onClick={() => {
                  setLiveViewMode(DAILY);
                }}
                variant="outlined"
              >
                Daily
              </Button>
              <Button
                className={'view-mode'}
                color={viewMode === WEEKLY ? 'secondary' : 'primary'}
                onClick={() => {
                  setLiveViewMode(WEEKLY);
                }}
                variant="outlined"
              >
                Weekly
              </Button>
              <Button
                className={'view-mode'}
                color={viewMode === MONTHLY ? 'secondary' : 'primary'}
                onClick={() => {
                  setLiveViewMode(MONTHLY);
                }}
                variant="outlined"
              >
                Monthly
              </Button>
            </>
          }
          title="Anayltic Data"
        />
        <Divider />
        <CardContent>
          <div className={classes.chartContainer}>
            <Bar
              data={data}
              options={options}
            />
          </div>
        </CardContent>
        <Divider />
        <CardActions className={classes.actions}>
          {/*<Button
            color="primary"
            size="small"
            variant="text"
          >
            Overview <ArrowRightIcon />
          </Button>*/}
        </CardActions>
      </Card>
    );
  }
}

LatestSales.propTypes = {
  className: PropTypes.string,
  analytics: PropTypes.object,
  viewMode: PropTypes.string.isRequired,
  argument: PropTypes.string.isRequired
};

export default compose(
  withStyles(styles, {
    name: 'LatestSales',
  }),
  connect(
    state => (
      {
        analytics: state.analytics.analytics,
        viewMode: state.analytics.liveViewMode,
        argument: state.analytics.argument
      }),
    {
      getAnalytics,
      setLiveViewMode,
      setAnalyticsArgument
    }
  ),
)(LatestSales);
