import store from '../core/store';

export function connection(data) {
  console.log('Connection : ', data);
}

export function getAnalytics(analytics) {
  console.log('Analytics : ', analytics);
  store.dispatch({type: 'GET_ANALYTICS_SUCCESS', socket: true, analytics: analytics});
}
