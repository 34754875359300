import React from 'react';
import PropTypes from 'prop-types';
import connect from 'react-redux/es/connect/connect';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import Socket from './Socket'
import {
    DAILY,
    MONTHLY,
    WEEKLY,
} from '../../constants';
import { setLiveViewMode } from '../../actions/analytic.action';
import {
    Button
} from '@material-ui/core';

import {
    LatestSales,
    Occupied,
    Vacant
} from './components';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    }
}));

const Live = ({ viewMode, setLiveViewMode }) => {
    const classes = useStyles();

    return ( <
        div className = { classes.root } >
        <Grid container spacing = { 4 } >

        <Grid item lg = { 3 }
        sm = { 6 }
        xl = { 3 }
        xs = { 12 } >
        <Occupied/>
        </Grid>

        <Grid item lg = { 3 }
        sm = { 6 }
        xl = { 3 }
        xs = { 12 } >
        <Vacant/>
        </Grid>

        <Grid item lg = { 12 }
        md = { 12 }
        xl = { 12 }
        xs = { 12 } >
        <LatestSales />
        </Grid>

        {
            /*<Grid
                      item
                      lg={4}
                      md={6}
                      xl={3}
                      xs={12}
                    >
                      <Button
                        className={'view-mode'}
                        color={viewMode === DAILY ? 'secondary' : 'primary'}
                        variant="outlined"
                        onClick={() => {
                          setLiveViewMode(DAILY);
                        }}
                      >
                    Daily
                      </Button>
                      <Button
                        className={'view-mode'}
                        color={viewMode === WEEKLY ? 'secondary' : 'primary'}
                        variant="outlined"
                        onClick={() => {
                          setLiveViewMode(WEEKLY);
                        }}
                      >
                    Weekly
                      </Button>
                      <Button
                        className={'view-mode'}
                        color={viewMode === MONTHLY ? 'secondary' : 'primary'}
                        variant="outlined"
                        onClick={() => {
                          setLiveViewMode(MONTHLY);
                        }}
                      >
                    Monthly
                      </Button>
                    </Grid>*/
        }
        </Grid>
      </div >
    );
};

Live.prototype = {
    viewMode: PropTypes.string.isRequired,
};

export default connect(
    state => ({
        viewMode: state.analytics.liveViewMode,
    }), {
        setLiveViewMode,
    }
)(Live);
