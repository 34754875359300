import request from '../services/request';
import {analyticApiRoutes} from '../configs/routes'

export const GET_ANALYTICS_REQUEST = 'GET_ANALYTICS_REQUEST';
export const GET_ANALYTICS_SUCCESS = 'GET_ANALYTICS_SUCCESS';
export const GET_ANALYTICS_FAILURE = 'GET_ANALYTICS_FAILURE';
export const SET_LIVE_VIEW_MODE = 'SET_LIVE_VIEW_MODE';
export const SET_ANALYTICS_ARGUMENT = 'SET_ANALYTICS_ARGUMENT';

export function getAnalytics() {
  return dispatch => {
    dispatch({type: GET_ANALYTICS_REQUEST, gettingAnalytics: true});
    request(analyticApiRoutes.ANALYTIC_ROUTE, {method: 'get'}).then(analytics => {
      // dispatch({type: GET_ANALYTICS_SUCCESS, analytics: analytics});
    },
    error => {
      dispatch({type: GET_ANALYTICS_FAILURE, getAnalyticsError: error.toString()});
    }
    );
  };
}

export function refreshAnalytics() {
  return dispatch => {
    request(analyticApiRoutes.ANALYTIC_ROUTE, {method: 'get'}).then(analytics => {
      // dispatch({type: GET_ANALYTICS_SUCCESS, analytics: analytics});
    },
    error => {
      dispatch({type: GET_ANALYTICS_FAILURE, getAnalyticsError: error.toString()});
    }
    );
  };
}

export function setLiveViewMode(mode) {
  return dispatch => {
    dispatch({type: SET_LIVE_VIEW_MODE, mode});
  }
}

export function setAnalyticsArgument(argument) {
  return dispatch => {
    dispatch({type: SET_ANALYTICS_ARGUMENT, argument});
  }
}
