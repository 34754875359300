import React from 'react';
import {compose} from 'redux';
import PropTypes from 'prop-types';
import connect from 'react-redux/es/connect/connect';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button, withStyles, Grid
} from '@material-ui/core';
import {DAILY, MONTHLY, REFRESH_CYCLE, WEEKLY} from '../../../../constants';
import clsx from 'clsx';
import { Bar } from 'react-chartjs-2';
import {
  options,
} from './chart';
import {
  getChartData,
} from './ChartLogic';
/*import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';*/

import {
  getAnalytics,
  refreshAnalytics,
  setLiveViewMode,
} from '../../../../actions/analytic.action';

const styles = theme => (
  {
    root: {},
    chartContainer: {
      height: 400,
      position: 'relative'
    },
    actions: {
      justifyContent: 'flex-end'
    }
  });

class LatestSales extends React.Component {
  state ={
    timer: null,
    counter: 0,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const {
      getAnalytics,
    } = this.props;

    let timer = setInterval(this.tick, 1000);
    this.setState({timer});
    getAnalytics();
  }

  componentWillUnmount() {
    clearInterval(this.state.timer);
  }

  tick = () => {
    this.setState({
      counter: this.state.counter + 1
    });
  };

  render() {
    const {
      classes,
      className,
      analytics,
      viewMode,
      refreshAnalytics,
      setLiveViewMode,
      ...rest
    } = this.props;

    const {counter} = this.state
    if (counter % REFRESH_CYCLE === 0) {
      refreshAnalytics();
    }

    const data = getChartData(analytics, viewMode);

    return (
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <CardHeader
          /*action={
            <Button
              size="small"
              variant="text"
            >
              Last 7 days <ArrowDropDownIcon />
            </Button>
          }*/
          action={
            <>
              {/*
              <Button
                className={'view-mode'}
                color={viewMode === DAILY ? 'secondary' : 'primary'}
                onClick={() => {
                  setLiveViewMode(DAILY);
                }}
                variant="outlined"
              >
              Daily
              </Button>

              <Button
                className={'view-mode'}
                color={viewMode === WEEKLY ? 'secondary' : 'primary'}
                onClick={() => {
                  setLiveViewMode(WEEKLY);
                }}
                variant="outlined"
              >
            Weekly
              </Button>

              <Button
                className={'view-mode'}
                color={viewMode === MONTHLY ? 'secondary' : 'primary'}
                onClick={() => {
                  setLiveViewMode(MONTHLY);
                }}
                variant="outlined"
              >
            Monthly
              </Button>
              */}
            </>
          }
          title="Daily Live Data"
        />
        <Divider />
        <CardContent>
          <div className={classes.chartContainer}>
            <Bar
              data={data}
              options={options}
            />
          </div>
        </CardContent>
        <Divider />
        <CardActions className={classes.actions}>
          {/*<Button
            color="primary"
            size="small"
            variant="text"
          >
            Overview <ArrowRightIcon />
          </Button>*/}
        </CardActions>
      </Card>
    );
  }
}

LatestSales.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  analytics: PropTypes.object,
  viewMode: PropTypes.string.isRequired,
};

export default compose(
  withStyles(styles, {
    name: 'LatestSales',
  }),
  connect(
    state => (
      {
        analytics: state.analytics.analytics,
        viewMode: state.analytics.liveViewMode,
      }),
    {
      getAnalytics,
      setLiveViewMode,
      refreshAnalytics,
    }
  ),
)(LatestSales);
