import axios from 'axios';
import {errorConfig} from '../configs/errors';

async function request(url, params = {}) {
  
  const headers = {
      'Content-Type': params.contentType || 'application/json'
    },
    config = {
      method: params.method || 'get',
      url: url,
      responseType: params.responseType || 'json',
      timeout: params.timeout || 0,
      headers: (
        params.body && (params.body.search || params.body.filters)) ? Object.assign(params.body, headers) : headers
    };
  if (params.body) {
    config.data = params.body;
  }
  
  return axios(config).then(res => res.data).catch(err => {
    if (err.response && err.response.data.error) throw err.response.data.error.message;
    else if (err.response && err.response.data.errors.length) {
      let errors = '';
      err.response.data.errors.forEach(({message}) => {
        errors += message + '<br>';
      });
      throw errors;
    }
    else throw errorConfig.DEFAULT_ERROR_MESSAGE;
  });
}

export default request;
