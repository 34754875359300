import React, { Component } from 'react'

class Socket extends Component {
  constructor(props) {
    super(props);
    this.state = {
      response: false
    };
  }

  componentDidMount() {

  }

  render() {
    const { response } = this.state;
    return (
        <div style={{ textAlign: 'center' }}>
          {response
              ? <p>
                After CI/CD Data Reading: { response}
              </p>
              :<p></p> /* <p>Loading...</p>*/
          }
        </div>
    )
  }
}

export default Socket;
