import {
  connection,
  getAnalytics
} from '../actions/socket_handler.action';

const handlers = [
  {
    channel: 'connection',
    action: connection
  },
  {
    channel: 'get-analytics',
    action: getAnalytics
  }
];
export default handlers;
