import {DAILY} from '../constants';
import {
  GET_ANALYTICS_REQUEST, GET_ANALYTICS_SUCCESS, GET_ANALYTICS_FAILURE, SET_LIVE_VIEW_MODE, SET_ANALYTICS_ARGUMENT
} from '../actions/analytic.action';

export function analyticReducer(state = {
  analytics: null,
  liveViewMode: DAILY,
  gettingAnalytics: false,
  getAnalyticsSuccess: false,
  getAnalyticsError: null,
  argument: "Humidity"
}, action) {
  switch (action.type) {
    case GET_ANALYTICS_REQUEST:
      return {
        ...state,
        gettingAnalytics: true
      };
    case GET_ANALYTICS_SUCCESS:
      return {
        ...state,
        analytics: action.analytics,
        gettingAnalytics: false,
        getAnalyticsSuccess: true
      };
    case GET_ANALYTICS_FAILURE:
      return {
        ...state,
        gettingAnalytics: false,
        getAnalyticsSuccess: false,
        getAnalyticsError: action.getAnalyticsError
      };
    case SET_LIVE_VIEW_MODE:
      return {
        ...state,
        liveViewMode: action.mode
      }
    case SET_ANALYTICS_ARGUMENT:
      return {
        ...state,
        argument: action.argument
      }
    default:
      return state;
  }
}
