/**
 * Client and API hosts
 * @type {string}
 */
/*
export const API_HOST = 'http://localhost:3001';
export const CLIENT_HOST = 'http://localhost:3000';
*/

export const API_HOST = 'https://roomalytics.com';
export const CLIENT_HOST = 'https://roomalytics.com';

